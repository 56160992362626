"use client";
import { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { useRouter } from "next/navigation";

interface UserType {
  tg_id: string | null;
  twitter_id: string | null;
  tg_username: string | null;
  twitter_username: string | null;
}

interface SupabaseContextProps {
  checkUser: (user_id: string, username: string, type: string) => void;
  twitter: string | null;
  tg: string | null;
  loading: boolean;
}

const SupabaseContext = createContext<SupabaseContextProps | undefined>(undefined);

export const SupabaseProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const [twitter, setTwitter] = useState<string | null>(null);
  const [tg, setTg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const tgUsername = localStorage.getItem("tg_username");
    const twitterUsername = localStorage.getItem("twitter_username");
    if (tgUsername) setTg(tgUsername);
    if (twitterUsername) setTwitter(twitterUsername);
    setLoading(false);
  }, []);

  const checkUser = async (user_id: string, username: string, type: string) => {
    setLoading(true);
    try {
      const response = await fetch("/api/checkUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id, username, type }),
      });

      const result = await response.json();

      if (result.error) {
        console.error("Error checking user");
        handleUserCreationOrUpdate(user_id, username, type);
      } else if (result.data) {
        updateLocalStorageAndState(result.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserCreationOrUpdate = async (user_id: string, username: string, type: string) => {
    const otherType = type === "tg_id" ? "twitter_id" : "tg_id";
    const otherId = localStorage.getItem(otherType);
    if (otherId) {
      const user =
        type === "tg_id"
          ? {
              tg_id: user_id,
              tg_username: username,
              twitter_id: otherId,
              twitter_username: localStorage.getItem("twitter_username"),
            }
          : {
              tg_id: otherId,
              tg_username: localStorage.getItem("tg_username"),
              twitter_id: user_id,
              twitter_username: username,
            };
      await updateUser(user, otherType, otherId);
    } else {
      const newUser =
        type === "tg_id"
          ? {
              tg_id: user_id,
              tg_username: username,
              twitter_id: null,
              twitter_username: null,
            }
          : {
              tg_id: null,
              tg_username: null,
              twitter_id: user_id,
              twitter_username: username,
            };
      await createUser(newUser);
    }
  };

  const createUser = async (user: UserType) => {
    setLoading(true);
    try {
      const response = await fetch("/api/createUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const result = await response.json();
      if (result.error) {
        console.error("Error creating user");
      } else {
        updateLocalStorageAndState(user);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      router.refresh();
    }
  };

  const updateUser = async (user: UserType, type: string, user_id: string) => {
    setLoading(true);
    try {
      const response = await fetch("/api/updateUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user, type, user_id }),
      });
      const result = await response.json();
      if (result.error) {
        console.error("Error updating user");
      } else {
        updateLocalStorageAndState(user);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateLocalStorageAndState = (data: UserType) => {
    if (data.tg_id) {
      localStorage.setItem("tg_id", data.tg_id);
      localStorage.setItem("tg_username", data.tg_username!);
      setTg(data.tg_username);
    }
    if (data.twitter_id) {
      localStorage.setItem("twitter_id", data.twitter_id);
      localStorage.setItem("twitter_username", data.twitter_username!);
      setTwitter(data.twitter_username);
    }
  };

  return <SupabaseContext.Provider value={{ checkUser, twitter, tg, loading }}>{children}</SupabaseContext.Provider>;
};

export const useSupabase = () => {
  const context = useContext(SupabaseContext);
  if (!context) {
    throw new Error("useSupabase must be used within a SupabaseProvider");
  }
  return context;
};
