"use client";
import { ChakraProvider } from "@chakra-ui/react";
import { Analytics } from "@vercel/analytics/react";
import { extendTheme } from "@chakra-ui/react";
import { SupabaseProvider } from "@/context/SupabaseProvider";

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const theme = extendTheme({
    initialColorMode: "light",
    useSystemColorMode: false,
    colors: {
      textColor: "#ffffff",
      buttonColor: "#4299E1",
      background: "#1a202c",
    },
    styles: {
      global: {
        "html, body": {
          color: "textColor",
          backgroundColor: "background",
        },
      },
    },
  });

  return (
    <html lang="en">
      <body>
        <ChakraProvider theme={theme}>
          <SupabaseProvider>
            {children}
            <Analytics />
          </SupabaseProvider>
        </ChakraProvider>
      </body>
    </html>
  );
}
